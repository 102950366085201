<script>
import appConfig from "@/app.config";
import utils from "../../../../helpers/utils";

/**
 * Advanced table component
 */
export default {
  props: ["telecallers_data", "filters","total_logins"],
  components: {},
  watch: {
    total_logins(v){
      this.summary_statuses[this.summary_statuses.findIndex(f => f.key == 'total_logins')].value = v;
    },
    telecallers_data: {
      deep: true,
      handler(v) {
        this.telecallers = v;
        this.totalRows = this.telecallers.length;
        this.getIncallStatusSummary();
      },
    },
    filters: {
      deep: true,
      handler() {
        this.getIncallStatusSummary();
      },
    },
  },
  data() {
    return {
      summary_statuses: [
      {
        "key" : "total_logins",
        "display" : "Logins (Today)",
        "value" : 0,
        "icon" : "ri-login-box-line"
      },
      {
        "key" : "users_called",
        "display" : "Users Called",
        "value" : 0,
        "icon" : "ri-user-3-line"
      },
      {
        "key" : "calls_done",
        "display" : "Calls made",
        "value" : 0,
        "icon" : "mdi mdi-doctor"
      },
      {
        "key" : "successful_calls",
        "display" : "Successful Calls",
        "value" : 0,
        "icon" : "ri-information-line",
        "color" : "background: rgb(191, 236, 223);"
      },
      {
        "key" : "whatsapp_sent",
        "display" : "Whatsapp Sent",
        "value" : 0,
        "icon" : "ri-whatsapp-line",
      },
      {
        "key" : "user_added_doc",
        "display" : "Users added doctors",
        "value" : 0,
        "icon" : "ri-user-3-line"
      },
      {
        "key" : "doc_added",
        "display" : "Doctors added",
        "value" : 0,
        "icon" : "mdi mdi-doctor"
      },
    ],
      telecallers: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "total_calls",
      sortDesc: true,
      fields: [],
      statuses: [],
      key: 0,
      tipData: { title: "Total of Call Successful and Call Done with more info Calls." }
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.telecallers.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.fields = [{ key: "username", sortable: true, label: "Field MR" }];
    this.fields.push( {
      key: "doctors_added",
      sortable: true,
    },
    {
      key: "whatsapp_sent",
      sortable: true,
    },
    {
      key: "total_calls",
      sortable: true,
    })
    appConfig.call_statuses.forEach((s) => {
      this.fields.push({
        key: s.replaceAll("_", " "),
        sortable: true,
        label: s.replaceAll("_", " "),
      });
    });
    this.fields.push(
      {
        key: "pending_calls",
        sortable: true,
      },
      {
        key: "total_call_duration",
        sortable: true,
      },
      {
        key: "avg_call_duration",
        sortable: true,
      },
    );
    this.statuses = appConfig.call_statuses;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    getFormatedTime(e) {
      return utils(e);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getIncallStatusSummary() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/telecallers/callStatusesSummaries?is_field_mr=true",
          {
            params: this.filters,
          }
        );
        this.telecallers.map((t) => {
          this.fields.forEach((f) => {
            if (f.key !== "username") {
              delete t[f.key];
            }
          });
        });
        this.key += 1;
        response.data.call_statuses_summary.map((res) => {
          let index = this.telecallers.findIndex(
            (t) => t.id === res.telecaller_id
          );
          if (isNaN(this.telecallers[index].total_calls)) {
            this.telecallers[index].total_calls = 0;
            this.telecallers[index].total_call_duration = 0;
          }
          this.telecallers[index][res.product_call_status] = res.total_calls;
          this.telecallers[index].total_calls += parseInt(res.total_calls);
          this.telecallers[index].total_call_duration += parseInt(
            res.total_call_duration
          );
          this.telecallers[index].avg_call_duration =
            this.telecallers[index].total_call_duration /
            this.telecallers[index].total_calls;
          if (isNaN(this.telecallers[index].avg_call_duration)) {
            this.telecallers[index].avg_call_duration = 0;
          }
        });
        response.data.unused_allocation.map((res2) => {
          let index = this.telecallers.findIndex(
            (t) => t.id === res2.telecaller_id
          );
          this.telecallers[index]['pending_calls'] = res2.total_count;
        });
        response.data.added_doctors.filter(a => a.project_id == 116).map((res2) => {
          let index = this.telecallers.findIndex(
              (t) => t.id === res2.telecaller_id
          );
          this.telecallers[index].doctors_added = res2.total_count;
        });
        let user_added_doc_index = this.summary_statuses.findIndex(f => f.key == 'user_added_doc');
        this.summary_statuses[user_added_doc_index].value = (response.data.added_doctors.filter(k => k.project_id == 116).map(s => { return s.telecaller_id})).length;
        let doc_added_index = this.summary_statuses.findIndex(f => f.key == 'doc_added');
        this.summary_statuses[doc_added_index].value = (response.data.added_doctors.filter(k => k.project_id == 116).map(s => { return s.total_count})).reduce((a,b) => {return a+b},0);
        let calls_done_index = this.summary_statuses.findIndex(f => f.key == 'calls_done');
        this.summary_statuses[calls_done_index].value = (response.data.call_statuses_summary.map(a => { return a.total_calls})).reduce((a,b) => {return a+b}, 0);
        let users_called_index = this.summary_statuses.findIndex(a => a.key == 'users_called');
        this.summary_statuses[users_called_index].value = [...new Set(response.data.call_statuses_summary.map(a => {return a.telecaller_id}))].length;
        let successful_calls_index = this.summary_statuses.findIndex(a => a.key == "successful_calls");
        this.summary_statuses[successful_calls_index].value = response.data.call_statuses_summary.filter(a => ["CALL DONE WITH MORE INFO NEEDED", "CALL SUCCESSFUL"].includes(a.product_call_status)).map(b => {return b.total_calls}).reduce((a,b) => {return a+b}, 0);
        let whatsapp_sent_index = this.summary_statuses.findIndex(a => a.key == 'whatsapp_sent');
        this.summary_statuses[whatsapp_sent_index].value = response.data.whatsapp_history.filter(f => this.telecallers.map(t => t.id).includes(f.telecaller_id)).map(a => {return a.total_count}).reduce((a,b) => {return a+b}, 0);
        response.data.whatsapp_history.forEach(f => {
          let index = this.telecallers.findIndex(
              (t) => t.id === f.telecaller_id
          );
          if(index !== -1){
            this.telecallers[index].whatsapp_sent = f.total_count;
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    getCellColumn(f) {
      return `cell(${f.replaceAll("_", " ")})`;
    },
  },
};
</script>

<template>
  <div>
    <div class="statues-grid">
      <div class="" v-for="(s,si) in summary_statuses" :key="si">
        <div class="card" :style="s.color">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <h4 class="card-title mb-0" style="font-size: 13px !important">{{s.display}}</h4>
              <template v-if="si == 3">
                <i class="ri-information-line" v-b-tooltip.hover.html="tipData" style="cursor: pointer"></i>  
              </template>
              <template v-else>
                <i :class="s.icon"></i>
              </template>
            </div>
            <h3>{{s.value}}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Total Call Summary</h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="telecallers"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :key="key"
              >
                <template
                  v-slot:[getCellColumn(f)]="row"
                  v-for="(f, fi) in statuses"
                >
                  <span v-if="row.value !== ''" :key="fi">{{ row.value }}</span>
                  <span v-else :key="fi">0</span>
                </template>
                <template v-slot:cell(pending_calls)="row">
                  <span v-if="row.value !== ''">{{ row.value }}</span>
                  <span v-else>0</span>
                </template>
                <template v-slot:cell(whatsapp_sent)="row">
                  <span v-if="row.value !== ''">{{ row.value }}</span>
                  <span v-else>0</span>
                </template>
                <template v-slot:cell(total_calls)="row">
                  <span v-if="row.value !== ''">{{ row.value }}</span>
                  <span v-else>0</span>
                </template>
                <template v-slot:cell(total_call_duration)="row">
                  <span v-if="row.value !== ''">{{
                    getFormatedTime(row.value)
                  }}</span>
                  <span v-else>0 sec</span>
                </template>
                <template v-slot:cell(avg_call_duration)="row">
                  <span v-if="row.value !== ''">{{
                    getFormatedTime(row.value)
                  }}</span>
                  <span v-else>0 sec</span>
                </template>
                <template v-slot:cell(doctors_added)="row">
                  <span v-if="row.value !== ''">{{
                      row.value
                    }}</span>
                  <span v-else>0</span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.statues-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 10px;
}
</style>